import { normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, Transition as _Transition, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["alt", "title", "width", "height", "src"]
const _hoisted_2 = { class: "text-h6 text-white" }
const _hoisted_3 = {
  style: {"position":"absolute","top":"25px","right":"25px"},
  height: "64",
  alt: "Cookie",
  title: "Cookie",
  src: "https://cdn2.pagisto.com/graphics/icons/cookie/cookie_ganz.svg"
}
const _hoisted_4 = {
  style: {"position":"absolute","top":"25px","right":"25px"},
  height: "64",
  alt: "Cookie in brackets",
  title: "Cookie in brackets",
  src: "https://cdn2.pagisto.com/graphics/icons/cookie/cookie_angeknabbert.svg"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "column justify-start text-white" }
const _hoisted_7 = { class: "text-subtitle1 text-weight-medium" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_p_password_input = _resolveComponent("p-password-input")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_p_footer = _resolveComponent("p-footer")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page_sticky = _resolveComponent("q-page-sticky")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_scroll_observer = _resolveComponent("q-scroll-observer")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_layout = _resolveComponent("q-layout")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHr lpR lff" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, {
        elevated: false,
        class: _normalizeClass([{
                'q-pa-lg': _ctx.$q.screen.gt.sm,
                'q-pa-md': _ctx.$q.screen.lt.md,
            }, "bg-transparent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, {
            class: _normalizeClass(["row items-center justify-between text-white bg-transparent", {
                    'q-px-lg': _ctx.$q.screen.gt.xs,
                    'q-px-sm': _ctx.$q.screen.lt.sm
                }]),
            style: {"transition":"all .3s","border-radius":"20px"}
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("img", {
                alt: Object.values(_ctx.content.logo || '')[1][0].alt,
                title: Object.values(_ctx.content.logo || '')[1][0].alt,
                width: _ctx.$q.screen.lt.sm ? '92' : '125',
                height: _ctx.$q.screen.lt.sm ? '92' : '125',
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: `main_${_ctx.$route.meta.lang}`, hash: '' }))),
                style: _normalizeStyle(`
                        height: ${_ctx.$q.screen.lt.sm ? '92' : '125'}px;
                        height: ${_ctx.$q.screen.lt.sm ? '92' : '125'}px;
                        cursor: pointer`),
                src: Object.values(_ctx.content.logo || '')[1][0].url
              }, null, 12, _hoisted_1), [
                [_directive_ripple]
              ])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_q_page_container, { style: {"padding-top":"0"} }, {
        default: _withCtx(() => [
          (_ctx.p_is_ok)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
            : (_openBlock(), _createBlock(_component_q_page, {
                key: 1,
                class: "column"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_p_password_input, {
                    onValid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.p_check_is_ok()))
                  })
                ]),
                _: 1
              })),
          _createVNode(_component_p_footer, { style: {"z-index":"100"} }),
          _createVNode(_component_q_page_sticky, {
            style: {"z-index":"101"},
            position: "bottom-right",
            offset: [50, 50]
          }, {
            default: _withCtx(() => [
              _createVNode(_Transition, {
                appear: "",
                "enter-active-class": "animated bounceInRight",
                "leave-active-class": "animated bounceOutRight"
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_q_btn, {
                    "aria-label": "eject icon",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: _ctx.$route.name || 'main_de-de', hash: '' }))),
                    padding: "xs",
                    round: "",
                    "text-color": "text2",
                    color: _ctx.$q.dark.isActive ? 'accent3' : 'accent',
                    icon: "eject"
                  }, null, 8, ["color"]), [
                    [_vShow, _ctx.scrollInfo.position.top > _ctx.$q.screen.height / 2]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_scroll_observer, { onScroll: _ctx.onScroll }, null, 8, ["onScroll"]),
      _createVNode(_component_q_dialog, {
        modelValue: _ctx.open_cookies_warning,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.open_cookies_warning) = $event)),
        persistent: "",
        onShow: _cache[9] || (_cache[9] = ($event: any) => (_ctx.get_cookie_warning_data()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card, {
            class: "bg-primary",
            flat: "",
            style: {"max-width":"600px"},
            maximized: _ctx.$q.screen.gt.sm
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { style: {"position":"relative","width":"100%"} }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(Object.values(_ctx.cookie_warning_content.title || { de: '' })[1]), 1),
                  _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
                    [_vShow, !_ctx.cookies.ilikecookies]
                  ]),
                  _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
                    [_vShow, _ctx.cookies.ilikecookies]
                  ])
                ]),
                _: 1
              }),
              (!_ctx.cookie_settings.open)
                ? (_openBlock(), _createBlock(_component_q_card_section, { key: 0 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "q-mt-md text-white",
                        style: {"font-size":".6rem"},
                        innerHTML: Object.values(_ctx.cookie_warning_content.message || { de: '' })[1]
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.cookie_settings.open)
                ? (_openBlock(), _createBlock(_component_q_card_actions, {
                    key: 1,
                    align: "right"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggle_cookie_settings())),
                        flat: "",
                        label: _ctx.$t('app.cookie_warning.buttons.more'),
                        color: "white",
                        loading: _ctx.cookies_loading,
                        size: "sm",
                        "no-caps": ""
                      }, null, 8, ["label", "loading"]),
                      _createVNode(_component_q_space),
                      _createVNode(_component_q_btn, {
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.accept_all_cookies())),
                        label: _ctx.$t('app.cookie_warning.buttons.accept_all'),
                        "no-caps": "",
                        "text-color": "primary",
                        loading: _ctx.cookies_loading,
                        color: "secondary"
                      }, null, 8, ["label", "loading"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.cookie_settings.open)
                ? (_openBlock(), _createBlock(_component_q_card_section, {
                    key: 2,
                    style: {"margin-top":"-16px"},
                    class: "column justify-start items-start no-wrap"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggle_cookie_settings())),
                        flat: "",
                        label: _ctx.$t('app.cookie_warning.buttons.back'),
                        color: "text",
                        icon: "chevron_left",
                        loading: _ctx.cookies_loading,
                        size: "sm",
                        "no-caps": ""
                      }, null, 8, ["label", "loading"]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.cookie_warning_content.elements || { de: [] })[1], (element, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "row justify-start items-start no-wrap q-mt-md"
                        }, [
                          (Object.values(element.data.name)[1] === 'essentials')
                            ? (_openBlock(), _createBlock(_component_q_toggle, {
                                key: 0,
                                class: "q-mr-md",
                                color: "secondary",
                                disable: "",
                                "model-value": _ctx.cookies.essentials
                              }, null, 8, ["model-value"]))
                            : (_openBlock(), _createBlock(_component_q_toggle, {
                                key: 1,
                                class: "q-mr-md",
                                color: "secondary",
                                modelValue: _ctx.cookies[Object.values(element.data.name)[1]],
                                "onUpdate:modelValue": ($event: any) => ((_ctx.cookies[Object.values(element.data.name)[1]]) = $event)
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])),
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, _toDisplayString(Object.values(element.data.title)[1]), 1),
                            _createElementVNode("div", {
                              class: "text-body2",
                              style: {"font-size":"0.8em"},
                              innerHTML: Object.values(element.data.description)[1]
                            }, null, 8, _hoisted_8)
                          ])
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.cookie_settings.open)
                ? (_openBlock(), _createBlock(_component_q_card_actions, {
                    key: 3,
                    align: "right"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.accept_selected_cookies())),
                        flat: "",
                        label: _ctx.$t('app.cookie_warning.buttons.accept_selection'),
                        color: "white",
                        loading: _ctx.cookies_loading,
                        size: "sm",
                        "no-caps": ""
                      }, null, 8, ["label", "loading"]),
                      _createVNode(_component_q_space),
                      _createVNode(_component_q_btn, {
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.accept_all_cookies())),
                        label: _ctx.$t('app.cookie_warning.buttons.accept_all'),
                        "no-caps": "",
                        loading: _ctx.cookies_loading,
                        "text-color": "primary",
                        color: "secondary"
                      }, null, 8, ["label", "loading"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["maximized"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}