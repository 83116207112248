import { API_Query_Data_Object } from '../API'

export class CMS_SCHEMA_Cookie_Warning_Elements {
    name?: { [key: string]: string }
    description?: { [key: string]: string }

    constructor () {
        this.name = { de: '' }
        this.description = { de: '' }
    }
}

export class CMS_SCHEMA_Cookie_Warning {
    title?: { [key: string]: string }
    message?: { [key: string]: string }
    elements?: { [key: string]: API_Query_Data_Object<CMS_SCHEMA_Cookie_Warning_Elements> }

    constructor () {
        this.title = { de: '' }
        this.message = { de: '' }
        this.elements = { de: { data: [new CMS_SCHEMA_Cookie_Warning_Elements()] } }
    }
}
