import { ApolloQueryResult } from '@apollo/client/core'

export interface API_Query_Data_Object <T> {
    __typename?: string
    id?: string
    data: T[]
}

export interface API_Query_Language_Data_Helper <T> {
    data: {
        [key: string]: T
    }
}

export interface API_Query_Data_Helper <T> {
    id?: string
    data: T
    __typename?: string
}

export class API_Query_Asset {
    url?: string
    mimeType?: string
    pixelWidth?: number | null
    pixelHeight?: number | null
    alt?: string | null
    focusX?: number | null
    focusY?: number | null
    imageQuality?: number | null

    constructor () {
        this.url = ''
        this.mimeType = ''
        this.pixelHeight = 0
        this.pixelWidth = 0
        this.alt = ''
        this.focusX = 0.5
        this.focusY = 0.5
    }
}

export type API_Query_Data<T> = ApolloQueryResult <API_Query_Data_Object <T>>
export type API_Query_Data_Sgn<T> = ApolloQueryResult <API_Query_Data_Helper <T>>
