import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["alt", "title", "src"]
const _hoisted_2 = ["alt", "title", "src"]
const _hoisted_3 = {
  class: "q-ma-none",
  style: {"font-size":"0.82em"}
}
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "q-mt-md full-width" }
const _hoisted_7 = {
  style: {"font-size":"0.82em","text-align":"justify"},
  class: "q-ma-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!

  return (_openBlock(), _createBlock(_component_q_toolbar, {
    class: _normalizeClass(["fit column wrap justify-start items-center bg-primary text-white", {
            'q-pa-xl': _ctx.$q.screen.gt.sm,
            'q-px-lg q-py-xl': _ctx.$q.screen.lt.md
        }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["fit row wrap justify-between items-center", { 'column': _ctx.$q.screen.lt.md }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'fit column justify-center items-center': _ctx.$q.screen.lt.md })
        }, [
          (!_ctx.$q.dark.isActive)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: _normalizeClass({ 'q-mb-sm': _ctx.$q.screen.lt.md }),
                width: "80",
                height: "80",
                alt: _ctx.content[0].data.logoLight?.iv[0].alt || '',
                title: _ctx.content[0].data.logoLight?.iv[0].alt || '',
                style: {"height":"80px","width":"80px"},
                src: _ctx.content[0].data.logoLight?.iv[0].url
              }, null, 10, _hoisted_1))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                width: "100",
                height: "100",
                alt: _ctx.content[0].data.logoDark?.iv[0].alt || '',
                title: _ctx.content[0].data.logoDark?.iv[0].alt || '',
                class: _normalizeClass({ 'q-mb-sm': _ctx.$q.screen.lt.md }),
                style: {"height":"100px","width":"100px"},
                src: _ctx.content[0].data.logoDark?.iv[0].url
              }, null, 10, _hoisted_2)),
          _createElementVNode("p", _hoisted_3, _toDisplayString(Object.values(_ctx.content[0].data.copyright || '')[1]), 1)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["full-height row justify-center items-center q-mx-lg", { 'q-my-md': _ctx.$q.screen.lt.md, 'full-width column justify-center items-center': _ctx.$q.screen.lt.sm }])
        }, [
          _createVNode(_component_q_btn, {
            "aria-label": "Jetzt Kontakt aufnehmen",
            color: "link",
            href: "mailto:office@nordzypern-top-invest.de?subject=Kontaktanfrage",
            flat: "",
            label: "Kontakt"
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.content[0].data.links || '')[1], (link) => {
            return (_openBlock(), _createBlock(_component_q_btn, {
              key: link,
              "aria-label": Object.values(link.data.refContent || '')[1].labelShort || '',
              color: "link",
              to: { name: Object.values(link.data.routing || '')[1].routeName },
              target: "_blank",
              flat: "",
              label: Object.values(link.data.refContent || '')[1].labelShort
            }, null, 8, ["aria-label", "to", "label"]))
          }), 128))
        ], 2),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            rel: "noreferrer",
            "aria-label": "Pagisto Instagram Link",
            href: Object.values(_ctx.content[0].data.instaUrl || '')[1],
            target: "__blank"
          }, [
            _createVNode(_component_q_img, {
              src: "https://cdn2.pagisto.com/graphics/icons/nti/instagram.svg",
              alt: "Pagisto Instagram Link",
              loading: "lazy",
              height: "32px",
              width: "32px"
            })
          ], 8, _hoisted_4),
          _createElementVNode("a", {
            class: "q-ml-md",
            rel: "noreferrer",
            "aria-label": "Pagisto Facebook Link",
            href: Object.values(_ctx.content[0].data.facebookUrl || '')[1],
            target: "__blank"
          }, [
            _createVNode(_component_q_icon, {
              color: _ctx.$q.dark.isActive ? 'secondary' : 'pagisto',
              size: "md",
              name: "facebook"
            }, null, 8, ["color"])
          ], 8, _hoisted_5)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["q-mx-lg full-height row", { 'q-mb-md': _ctx.$q.screen.lt.md, 'full-width column justify-center items-center': _ctx.$q.screen.lt.sm }])
        }, [
          _createVNode(_component_q_select, {
            modelValue: _ctx.locale,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locale) = $event)),
              _ctx.set_language
            ],
            options: _ctx.locale_options,
            dark: "",
            color: "primary",
            "popup-content-class": "bg-secondary text-primary",
            dense: "",
            borderless: "",
            "emit-value": "",
            "map-options": "",
            "options-dense": "",
            "option-label": opt => _ctx.$t(opt.label)
          }, null, 8, ["modelValue", "options", "onUpdate:modelValue", "option-label"])
        ], 2)
      ], 2),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(Object.values(_ctx.content[0].data.hint || '')[1]), 1)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}