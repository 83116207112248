import { API_Query_Asset, API_Query_Data_Helper } from '../API'
import { CMS_SCHEMA_External_Links } from './external_links'

export class CMS_SCHEMA_Header {
    logo?: { [key: string]: API_Query_Asset[] }
    link?: { [key: string]: API_Query_Data_Helper<CMS_SCHEMA_External_Links>[] }
    __typename?: string

    constructor () {
        this.logo = { abc: [], iv: [new API_Query_Asset()] }
        this.link = { abc: [], iv: [{ data: new CMS_SCHEMA_External_Links() }] }
        this.__typename = ''
    }
}
