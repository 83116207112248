export class CMS_SCHEMA_External_Links {
    id?: string
    label?: { [key: string]: string }
    href?: { [key: string]: string }

    constructor () {
        this.label = { de: '' },
        this.href = { de: '' }
    }
}
